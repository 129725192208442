import {
  CurrentStep,
  Deal,
  DealRecipe,
  DealStep,
  SelectedRecipe
} from '@/builders/deals/slice/dealTypes';
import DealType from '@/builders/deals/DealTypes';
import StepType from '@/builders/deals/StepType';
import { PIZZA, MELT } from '@/domain/constants';

const selectInitialCurrentStep = (
  steps: DealStep[],
  type: DealType
): CurrentStep | null => {
  if (steps.length > 1) return null;
  const [firstStep] = steps;
  const isMulti = firstStep?.recipeOptions?.length > 1;

  const getStepType = (recipeType: string) => {
    if (recipeType === PIZZA || recipeType === MELT) {
      return isMulti
        ? StepType.MULTI_PIZZA_RECIPE
        : StepType.SINGLE_PIZZA_RECIPE;
    }

    return StepType.MENU_RECIPE;
  };

  switch (type) {
    case DealType.PARENT:
    case DealType.ORDER_LEVEL:
      return null;

    case DealType.SINGLE_STEP: {
      const {
        id, name, description, required, price, recipeOptions
      } = firstStep;
      const [{ id: recipeId, type: recipeType }] = recipeOptions;
      return {
        id,
        type: getStepType(recipeType),
        name,
        description,
        required,
        recipeId: isMulti ? null : recipeId,
        recipeType: isMulti ? null : recipeType,
        recipeOptions,
        price,
        index: 0,
        isSwap: false
      };
    }

    case DealType.MULTI_STEP: {
      const { required, price, recipeOptions } = firstStep;
      const { type: recipeType } = recipeOptions[0];
      return {
        id: '',
        type: getStepType(recipeType),
        name: '',
        description: null,
        required,
        recipeId: null,
        recipeType: null,
        recipeOptions,
        price,
        index: 0,
        isSwap: false
      };
    }

    default:
      return null;
  }
};

const selectCurrentStep = (deal: Deal): CurrentStep | null => deal.userSelections.present.currentStep;

const selectRecipesForCurrentStep = (deal: Deal): DealRecipe[] | undefined => {
  const { id: stepId } = selectCurrentStep(deal) || {};
  return deal.data.steps.find((step) => step.id === stepId)?.recipeOptions;
};

const selectCurrentStepDealRecipe = (
  deal: Deal
): SelectedRecipe | undefined => {
  const currentStep = selectCurrentStep(deal);

  return deal.userSelections.present.recipes.find(
    (recipe) => ((recipe.stepId === currentStep?.id) && (recipe.index === currentStep?.index))
  );
};

const selectSelectedRecipeByStepId = (deal: Deal) => (stepId: DealStep['id'], index: number): SelectedRecipe | undefined => {
  const { recipes } = deal.userSelections.present;
  return recipes.find((recipe) => ((recipe.stepId === stepId && recipe.index === index)));
};

const selectRedeemLoyalty = (deal: Deal) => deal.userSelections.present.redeemingLoyaltyDeal;

export {
  selectSelectedRecipeByStepId,
  selectCurrentStepDealRecipe,
  selectRecipesForCurrentStep,
  selectCurrentStep,
  selectInitialCurrentStep,
  selectRedeemLoyalty
};
