import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CCStoreResponse, DeliveryAddress, DiningOccasion, LocalizeCustomerCartInput, OrderActions, storeApiInfo, useCreateCustomerCartMutation,
  useResetCustomerCartMutation
} from '@pizza-hut-us-development/client-core';
import {
  closeLocalizationRail,
  openModal,
  resetToNationalStore,
  setLegacyLocalizationState
} from '@/localization/actions';
import { toggleCartLoadingStatus } from '@/clientCore/redux/cart/CartSlice';
import standaloneApiClient from '@/api/standaloneApiClient';
import StandaloneEndpoints from '@/api/standaloneApiClient/endpoints';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { localizationSelectors } from '@/localization/localizationSelectors';
import router from '@/router';
import telemetry from '@/telemetry';
import { setCookie } from '@/cookies/handlers';
import { COOKIES } from '@/cookies/constants';
import { snapWaiverModalDetails } from '@/clientCore/localization/modalDetails';
import isOptimizelyFeatureEnabled from '../../../optimizely/utils/isOptimizelyFeatureEnabled';

const useLocalization = () => {
  const dispatch = useDispatch();

  const currentStore = useSelector(orderSelectors.store);
  const localizationOptions = useSelector(localizationSelectors.railOptions);

  const [createCartMutation] = useCreateCustomerCartMutation();
  const [resetCartMutation] = useResetCustomerCartMutation();

  const saveCartIdInCookie = useCallback(async (cartCCId: string) => {
    setCookie(COOKIES.CART_CC_ID, cartCCId);
  }, []);

  const saveLocalizationCookie = useCallback(async (payload: LocalizeCustomerCartInput) => {
    await standaloneApiClient.post(
      { payload },
      StandaloneEndpoints.CREATE_CC_LOCALIZATION_COOKIE
    );
  }, []);

  const saveStoreToken = useCallback(async (token?: string) => {
    await standaloneApiClient.post(
      { token },
      StandaloneEndpoints.SAVE_LOCALIZATION_TOKEN
    );
  }, []);

  const createCart = async (payload: LocalizeCustomerCartInput, onAfterCartLocalization: () => void, storeToken?: string) => {
    try {
      dispatch(toggleCartLoadingStatus({ loadingState: true }));
      if (currentStore && currentStore.storeNumber !== payload.storeNumber) {
        await resetCartMutation();
      }
      const response = await createCartMutation(payload);
      if ('data' in response) {
        await Promise.all([
          saveCartIdInCookie(response.data.cartId),
          saveLocalizationCookie(payload),
          saveStoreToken(storeToken)
        ]);
        onAfterCartLocalization();
      }
    } catch (error) {
      resetToNationalStore(dispatch);
    } finally {
      dispatch(toggleCartLoadingStatus({ loadingState: false }));
    }
  };

  const handlePostLocalization = (store: CCStoreResponse, occasion: DiningOccasion, deliveryAddress?: DeliveryAddress) => {
    if (occasion === DiningOccasion.DELIVERY) {
      telemetry.addCustomEvent('web2-select-delivery-store');
    } else {
      telemetry.addCustomEvent('web2-select-carryout-store');
    }

    // TODO: Remove after replacing legacy localization selectors in components
    setLegacyLocalizationState(dispatch, store, occasion, deliveryAddress);

    const snapWaiverEnabled = isOptimizelyFeatureEnabled('fr-web-4379-display-snap-waiver-modal')
    if(snapWaiverEnabled && occasion === DiningOccasion.CARRYOUT) {
      dispatch(openModal(snapWaiverModalDetails))
    }

    const { routeTo, handleAfterLocalizing } = localizationOptions;

    if (handleAfterLocalizing) {
      handleAfterLocalizing();
    }

    if (routeTo) {
      router.goToRoute(routeTo);
    }
  };

  const localizeCarryout = async (store: CCStoreResponse) => {
    dispatch(closeLocalizationRail());
    const { data: storeDetails } = await dispatch(storeApiInfo.endpoints.storeInfo.initiate(store.storeNumber)) as unknown as { data: CCStoreResponse };

    const storeInfo = {
      ...storeDetails,
      token: store.token
    } as CCStoreResponse;

    dispatch(OrderActions.setStore(storeInfo));

    await createCart({
      storeNumber: store.storeNumber,
      occasion: DiningOccasion.CARRYOUT
    }, () => handlePostLocalization(storeInfo, DiningOccasion.CARRYOUT), storeInfo.token);

  };

  const localizeDelivery = async (store: CCStoreResponse, deliveryAddress: DeliveryAddress) => {
    dispatch(closeLocalizationRail());
    const { data: storeDetails } = await dispatch(storeApiInfo.endpoints.storeInfo.initiate(store.storeNumber)) as unknown as { data: CCStoreResponse };

    const storeInfo = {
      ...storeDetails,
      token: store.token
    } as CCStoreResponse;

    dispatch(OrderActions.setStore(storeInfo));

    await createCart({
      storeNumber: store.storeNumber,
      occasion: DiningOccasion.DELIVERY,
      deliveryAddress: {
        ...deliveryAddress,
        countryCode: 'US'
      }
    }, () => handlePostLocalization(storeInfo, DiningOccasion.DELIVERY, deliveryAddress), storeInfo.token);
  };

  return {
    localizeCarryout,
    localizeDelivery
  };
};

export default useLocalization;
