import React, { useEffect, useState } from 'react';
import {
  initializeCCLocalization,
  localizationInitialized,
  selectStore
} from '@/localization/actions';
import {
  CoreConfigActions,
  DiningOccasion,
  SalesChannel
} from '@pizza-hut-us-development/client-core';
import { NATIONAL, Occasion } from '@/localization/constants';
import { setAuthState, setLogInStatusToFalse } from '@/header/actions';
import isClientSide from '@/common/isClientSide';
import { overrideCmsEnv, overrideGqlVersion, setGqlTimestamp } from './actions';
import telemetry from '@/telemetry';
import { init as initAuth } from '../auth';
import { AuthState } from '@/auth/userStates';
import { ReduxApplicationProps } from './types';
import { actions as salesExperienceActions } from '@/salesExperience/salesExperience.slice';
import * as getConfig from 'next/config';
import { setLastActiveTimestamp } from '@/auth/actions';
import debounce from 'just-debounce';
import { Provider } from 'react-redux';
import createStore from '@/store';
import { getLocalizationTokenDetail } from '@/localization/localizationToken';

interface PopulateStoreProps extends Partial<ReduxApplicationProps> {
  store?: any;
}

const { setStagingEnv, setProductionEnv, setAuthChannel, setSalesChannel } = CoreConfigActions;

const envs = {
    production: setProductionEnv(),
    staging: setStagingEnv(),
};

export function populateStore(props: PopulateStoreProps) {
  const {
    cmsEnvId,
    gqlVersion,
    timestamp,
    localizationToken,
    userAuthentication,
    storeNumber = NATIONAL,
    onStoreCreation = () => { },
    salesExperience,
    store = createStore(),
    yumAuthRefreshData,
    localizationData,
  } = props;

  const clientSide = isClientSide();
  const isLocalized = !!localizationData?.localizationDetails;
  const { publicRuntimeConfig } = getConfig.default();
  const coreProviderEnv =
    publicRuntimeConfig.ENVIRONMENT_NAME === 'prod' ? 'production' : 'staging';

  store.dispatch(envs[coreProviderEnv]);
  store.dispatch(setAuthChannel('WEB2'));
  store.dispatch(setSalesChannel(SalesChannel.WEB));
  store.dispatch(CoreConfigActions.setIsYumEcomm(true));
  store.dispatch(CoreConfigActions.setAxiosTimeout(35000));

  if (salesExperience)
    store.dispatch(salesExperienceActions.experience(salesExperience));
  telemetry.addCustomAttribute('storeNumber', storeNumber);
  telemetry.addCustomAttribute('salesExperience', String(salesExperience));

  const legacyLocalizationDetails = getLocalizationTokenDetail(localizationToken);

  // Note: initAuth() can trigger an asynchronous refresh for expired tokens, so
  // proceeding code needs to handle expired tokens
  initAuth(
    store,
    isLocalized,
    localizationToken,
    userAuthentication,
    yumAuthRefreshData,
    () => {
      if (clientSide && isLocalized && localizationData?.localizationDetails) {
        store.dispatch(initializeCCLocalization(localizationData.localizationDetails, localizationToken, localizationData.cartId));
      } else if (clientSide && legacyLocalizationDetails) {
        const  { storeNumber, address, occasion } = legacyLocalizationDetails;
        store.dispatch(initializeCCLocalization({
          occasion: occasion === Occasion.CARRYOUT ? DiningOccasion.CARRYOUT : DiningOccasion.DELIVERY,
          ...(occasion !== Occasion.CARRYOUT && {
            deliveryAddress: {
              address1: address.address,
              address2: address.address2,
              state: address.state,
              city: address.city,
              postalCode: address.zipcode,
              countryCode: 'US'
          }}),
          storeNumber
        }, localizationToken, localizationData?.cartId));
      }
    }
  );

  if (cmsEnvId) store.dispatch(overrideCmsEnv(cmsEnvId));
  if (gqlVersion) store.dispatch(overrideGqlVersion(gqlVersion));
  if (timestamp) store.dispatch(setGqlTimestamp(timestamp));

  if (
    clientSide &&
    (userAuthentication?.authState === AuthState.GUEST ||
      userAuthentication?.authState === AuthState.EXPIRED)
  ) {
    store.dispatch(setLogInStatusToFalse());
  }
  if (clientSide && userAuthentication) {
    store.dispatch(setAuthState(userAuthentication.authState));
  }


  if (clientSide && isLocalized && localizationData?.localizationDetails) {
    store.dispatch(selectStore(localizationData.localizationDetails.storeNumber));
  } else if (clientSide) {
    if (legacyLocalizationDetails) {
      const  { storeNumber } = legacyLocalizationDetails;
      store.dispatch(selectStore(storeNumber));
    } else {
      // Set unlocalized state
      store.dispatch(selectStore(NATIONAL));
      store.dispatch(localizationInitialized());
    }
  }

  onStoreCreation(store);

  return store;
}

export default function ReduxApplication(props: ReduxApplicationProps) {
  const { children, ...populateData } = props;
  const [store] = useState(() => populateStore({ ...populateData }));

  useEffect(() => {
    const debouncedActivityEventListener = debounce(() => store.dispatch(setLastActiveTimestamp(new Date().getTime())), 500);
    const events = ['scroll', 'keydown', 'keypress', 'keyup', 'click', 'dblclick'];

    function createUserActivityEventListeners() {
      events.forEach((event) => document.addEventListener(event, debouncedActivityEventListener));
    }

    function removeUserActivityEventListeners() {
      events.forEach((event) => document.removeEventListener(event, debouncedActivityEventListener));
    }

    if (isClientSide()) {
      createUserActivityEventListeners();
      return () => {
        removeUserActivityEventListeners()
      };
    }
    return undefined;
  }, []);

  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}