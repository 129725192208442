import {
  PizzaBuilder,
  Modifier,
  Rules,
  Rule,
  Item
} from '@pizza-hut-us-development/client-core';
import {
  crustBuilder,
  lookupInternalName,
  processCCPizzaAvailability,
  transfomCCModifiersToPortions,
  transformRulesLookupTable
} from './helpers/builderPizzaMicroTransforms';
import { IsCyo } from '@/menu/pizza/pizzaMenuTypes';
import { OccasionApi } from '@/localization/constants';
import { MEATS, VEGGIES } from '@/domain/constants';
import {
  CrustOption,
  IngredientOptionWithPortions,
  PizzaIngredientOption,
  PizzaSummaryOption
} from '@/builders/pizza/dataTransformers/builderTypes';
import { PizzaBuilderOptions } from '@/graphql/dynamicQueries/pizza';
import { HiddenProducts } from '@/api/reduxToolkit/hideProducts';
import { dealAddNoSauce } from '@/clientCore/hardCodingHelpers/WEB-2491-yumPizzaNoSauce/helpers';
import Placement from '@/common/Placement';
import isOptimizelyFeatureEnabled from '../../../../optimizely/utils/isOptimizelyFeatureEnabled';

interface PizzaBuilderItem extends Omit<Item, 'displayOrder' | 'nutrition'> {
  displayOrder?: number;
}

const buildSummary = (data: PizzaBuilderItem): PizzaSummaryOption => {
  // data is there however type does not reflect that
  const {
    id,
    isCYO,
    name,
    imageURL,
    splittable,
    outOfStock,
    sodiumWarning,
    glutenFree,
    price,
    displayOrder
  } = data;

  return {
    id,
    isCYO: isCYO ? IsCyo.TRUE : IsCyo.FALSE,
    name,
    image: imageURL,
    splittable,
    outOfStock,
    sodiumWarning,
    glutenFree,
    price,
    priority: displayOrder,
    showTotalPrice: true, //  Hard Coded in App phc-web/app/builders/pizza/dataTransformers/pizzaOptionsTransformer.ts:216
    hideCrustStepCarat: false //  Hard Coded in App phc-web/app/builders/pizza/dataTransformers/pizzaOptionsTransformer.ts:216
  };
};

const buildOptions = (options: Modifier[]): IngredientOptionWithPortions[] => options.map((option): IngredientOptionWithPortions => {
  const placementExcludedVariantsEnabled = isOptimizelyFeatureEnabled('fr-web-4352-placement-excluded-variants');

  const {
    id,
    name,
    required,
    type,
    price,
    selected,
    displayOrder,
    glutenFree,
    outOfStock,
    maxAllowed,
    splittable,
    description,
    defaultSelectedOption,
    sodiumWarning,
    isPanCrust,
    imageURL,
    modifiers,
    placement,
    nutrition,
    slotCode,
    weightCode,
    weights
  } = option;

  const transformedPlacement = placement ? {
    placement: Placement[placement]
  } : undefined;

  const portions = modifiers?.length
    ? transfomCCModifiersToPortions(modifiers)
    : [];
  const hasSelectedPortion = portions.length > 0 && portions.some((portion) => portion.selected);
  const isSelected = hasSelectedPortion || !!selected;
  const isToppingSplittable = weights?.find((weight) => weight.includes('LEFT') || weight.includes('RIGHT'));
  const excludedVariants = (placementExcludedVariantsEnabled && option.excludedVariants) || modifiers?.reduce((acc, obj) => ({ ...acc, ...obj.excludedVariants }), {});

  return {
    id,
    name,
    required,
    type: type ?? '',
    price,
    selected: isSelected,
    priority: displayOrder,
    glutenFree,
    outOfStock,
    maxAllowed: maxAllowed ?? undefined,
    splittable: !!isToppingSplittable || splittable,
    description,
    defaultSelectedOption,
    sodiumWarning,
    isPanCrust,
    image: imageURL,
    portions,
    nutrition: nutrition ?? null,
    slotCode,
    weightCode,
    weights,
    excludedVariants,
    ...transformedPlacement
  };
});

const getGlobalId = (id: string) => id?.split('/').pop();

const extractAndTransformToppings = (toppings: Modifier[], toppingToBeExcluded?: string[]) => {
  const meatToppings = buildOptions(
    toppings.find((topping) => topping?.name === MEATS)?.modifiers ?? []
  ).filter((meatTopping) => !toppingToBeExcluded?.includes(getGlobalId(meatTopping.id) ?? ''));

  const veggieToppings = buildOptions(
    toppings.find((topping) => topping?.name === VEGGIES)?.modifiers ?? []
  ).filter((veggieTopping) => !toppingToBeExcluded?.includes(getGlobalId(veggieTopping.id) ?? ''));

  return {
    meatToppings,
    veggieToppings
  };
};

type SeparatedSizesAndCrusts = {
  sizes: PizzaIngredientOption[];
  crusts: CrustOption[];
};

const extractAndTransformSizesAndCrusts = (
  sizesAndCrusts: Modifier[], crustsToBeExcluded?: string[]
): SeparatedSizesAndCrusts => {
  const { sizes, crusts } = sizesAndCrusts.reduce(
    (
      acc: SeparatedSizesAndCrusts,
      currentSet: Modifier
    ): SeparatedSizesAndCrusts => {
      // Everything except Modifiers used for size, modifiers are for building crusts.
      const {
        nutrition,
        id,
        name,
        required,
        type,
        price,
        selected,
        displayOrder,
        glutenFree,
        outOfStock,
        maxAllowed,
        splittable,
        description,
        defaultSelectedOption,
        sodiumWarning,
        isPanCrust,
        imageURL,
        modifiers
      } = currentSet;

      // Build Crusts

      return {
        sizes: [
          ...acc.sizes,
          {
            nutrition: nutrition ?? null,
            id,
            name,
            required,
            type: type ?? '',
            price,
            selected: Boolean(selected),
            priority: displayOrder,
            glutenFree,
            outOfStock,
            maxAllowed: maxAllowed ?? undefined,
            splittable,
            description,
            defaultSelectedOption,
            sodiumWarning,
            isPanCrust,
            image: imageURL
          }
        ],
        crusts: [...acc.crusts, ...crustBuilder(modifiers || [], id)].filter((crust) => !crustsToBeExcluded?.includes(getGlobalId(crust.id) ?? ''))
      };
    },
    { sizes: [], crusts: [] }
  );

  return { sizes, crusts };
};

const rebuildRules = (
  rules: Rules,
  rulesLookupTable: Record<string, PizzaIngredientOption[]>
) => {
  const internalnameByModifierTable = transformRulesLookupTable(rulesLookupTable);
  const rulesOperations = Object.keys(rules);
  const transformedRules = rulesOperations.reduce((acc, currentRuleKey) => {
    const rulesArrayByKey = rules[currentRuleKey as keyof Rules] as Rule[];
    if (rulesArrayByKey.length) {
      const newRuleSet = rulesArrayByKey.map((rule) => ({
        type: rule.actionItem.type,
        name: rule.actionItem.name,
        id: rule.actionItem.id,
        price: rule.actionItem.price,
        rule: rule.action,
        crustId: rule.crustId,
        sizeId: rule.sizeId,
        internalName: lookupInternalName(
          rule.actionItem.id,
          internalnameByModifierTable
        ) // NEED INTERNAL NAME ADDED ON. THIS IS THE POINTER USED FOR DETERMINING WHERE RULES ARE USED.
      }));
      return { ...acc, [currentRuleKey]: newRuleSet };
    }
    return acc;
  }, {});
  return transformedRules;
};

const transformSauces = (sauces: Modifier[], saucesToBeExcluded?: string[]) => buildOptions(sauces)
  .filter((sauce) => !saucesToBeExcluded?.includes(getGlobalId(sauce.id) ?? ''));

export const transformPizzaBuilderPizza = (
  data: PizzaBuilder,
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean,
  modifiersToHideData?: HiddenProducts
): PizzaBuilderOptions => {
  const { meatToppings, veggieToppings } = extractAndTransformToppings(
    data.toppings, modifiersToHideData?.toppings
  );

  const { sizes, crusts } = extractAndTransformSizesAndCrusts(
    data.sizesAndCrusts, modifiersToHideData?.crusts
  );

  const { available, availableInOtherOccasion } = processCCPizzaAvailability(
    data.availability,
    occasion,
    storeTimeZone,
    isYumEcomm
  );

  const transformedSauces = transformSauces(data.sauces, modifiersToHideData?.sauces);
  const sauces = dealAddNoSauce(transformedSauces);
  const cheeses = buildOptions(data.cheeses);
  const rulesLookupTable = {
    sizes,
    crusts,
    sauces,
    cheeses,
    meats: meatToppings,
    veggies: veggieToppings
  };

  return {
    summary: buildSummary(data),
    sizes,
    crusts,
    sauces,
    cheeses,
    finishers: [],
    meatToppings,
    veggieToppings,
    rules: rebuildRules(data.rules, rulesLookupTable),
    item: {
      name: data.name,
      outOfStock: Boolean(data.outOfStock),
      available,
      availableInOtherOccasion
    }
  };
};

