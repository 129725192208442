import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { OrderActions, useReassignCustomerCartMutation } from '@pizza-hut-us-development/client-core';
import { useRouter } from 'next/router';

import { AUTH_TOKEN_UPDATED_EVENT, AuthTokenUpdatedEventDetail } from '@/auth/authTokenHelpers';
import { RootState } from '@/rootStateTypes';
import { useCreateCart } from '@/clientCore/cart/hooks/useCreateCart';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { saveCartIdInCookie } from '@/localization/actions';
import Routes from '@/router/routes';

export const useCartRefresh = () => {
  const [createCart] = useCreateCart();
  const dispatch = useDispatch();
  const router = useRouter();

  const currentCart = useSelector(orderSelectors.cart);
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const [reassignCustomerCart] = useReassignCustomerCartMutation();

  const [{ enabled: cartReassignmentEnabled }] = useDecision('fr-web-3866-cart_reassignment');
  const [{ enabled: refreshCheckoutOnSignInEnabled }] = useDecision('fr-web-4332-refresh-checkout-on-sign-in');

  // TODO: Remove when authenticated users are supported when isYumEcomm = true
  // Watches for changes to the cart and isYumEcomm flag and recreates the cart if there
  // is ever a mismatch. This only occurs currently when toggling between a guest
  // and authenticated user session when a store has isYumEcomm = true for
  // only guest users
  useEffect(() => {
    if (!currentCart?.type) {
      return;
    }

    if ((isYumEcomm && currentCart.type !== 'YUM') || (!isYumEcomm && currentCart.type !== 'PHD')) {
      createCart();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCart?.type, isYumEcomm]);

  const reassignCart = useCallback(async (previousUserId: string, currentUserId: string) => {
    const response = await reassignCustomerCart({ currentCustomerID: previousUserId, newCustomerID: currentUserId });
    if ('data' in response) {
      dispatch(OrderActions.setCart(response.data));
      await saveCartIdInCookie(response.data.cartId);
    } else {
      throw new Error('error reassigning cart');
    }
  }, [dispatch, reassignCustomerCart]);

  // handles recreating a cart when auth tokens change after
  // logging in/out or after being refreshed
  useEffect(() => {
    const authTokenChangedEventHandler = async (event: Event) => {
      if (!currentCart) {
        return;
      }

      const { previousUserId, currentUserId } = (event as CustomEvent<AuthTokenUpdatedEventDetail>).detail || {};
      if (cartReassignmentEnabled && previousUserId && currentUserId) {
        try {
          await reassignCart(previousUserId, currentUserId);
          if (refreshCheckoutOnSignInEnabled && router.pathname === Routes.CHECKOUTW2) {
            router.reload();
          }
        } catch {
          await createCart();
        }
      } else {
        await createCart();
      }
    };

    window.addEventListener(AUTH_TOKEN_UPDATED_EVENT, authTokenChangedEventHandler);
    return () => {
      window.removeEventListener(AUTH_TOKEN_UPDATED_EVENT, authTokenChangedEventHandler);
    };
  }, [cartReassignmentEnabled, createCart, currentCart, reassignCart, refreshCheckoutOnSignInEnabled, router]);
};
