export function updateUrl(link: string): void {
  window.location.href = link;
}

/* DTG-988 DTG-987
  legacy link.php is still being used for the homepage content,
  added "deals/id" to the check for the new banners via contentful
*/
const isValidDeeplink = (link: string) => link?.includes('link.php') || link?.includes('/deals?');
const hasDealInLink = (link: string) => link?.includes('c=') || link?.includes('id=');

// Shouldn't show confirm, because localization happened recently
export const shouldShowConfirmLocationModal = (): boolean => false; // see W20M-417

export function getDealNameFromUrl(link: string): string | undefined {
  if (isValidDeeplink(link) && hasDealInLink(link)) {
    const isFullLink = link.startsWith('http');
    const isRelativeLink = link.startsWith('link.php') || link.startsWith('/link.php') || link.startsWith('/deals?');

    if (!isFullLink && !isRelativeLink) {
      return undefined;
    }

    const url = new URL(isFullLink ? link : `http://example.com/${link}`);
    const dealName = url.searchParams.get('c') || url.searchParams.get('id');


    return dealName || undefined;
  }

  return undefined;
}

/* The Deal URL requires an argument to let web1 know that we should route to the deals page,
   even when when an item in the cart is eligible for the deal */
export const createDealLink = (link: string, isRelativeLink: boolean) => {
  let enrichedLink = link;
  if (isValidDeeplink(link) && hasDealInLink(link)) {
    enrichedLink += '&bypass_deals_page=false';
  }

  return isRelativeLink ? `/${enrichedLink}` : enrichedLink;
};
